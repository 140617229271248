<template>
  <div>
    <Error404 v-if="error404occured" />
    <main v-else id="content" role="main" class="overflow-hidden">
      <!-- ========== Job Description ========== -->
      <div class="bg-light">
        <div class="container space-2 space-lg-3">
          <div>

            <!-- Breadcrumb -->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ path: '/blog' }">
                    Blog
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ position_detail.title }}</li>
              </ol>
            </nav>

            <div class="row">
              <!-- Content -->
              <div class="col-12 col-lg-8 mb-3 mb-lg-0">
                <div class="card">
                  <div class="card-body">
                    <h2>{{ position_detail.title }}</h2>
                    <img class="img-fluid my-6" :src="require('@/assets/img/apidays-2023/images/' + position_detail.image_url)">

                    <div class="mb-8">
                      <div id="about" />
                    </div>

                  </div>
                </div>
              </div>

              <!-- Sidebar -->
              <div class="col-12 col-lg-4">
                <div class="row">
                  <!-- <div class="col-12 col-md-6 col-lg-12 mb-6">
                    <div class="card h-100">
                      <div class="card-body">
                        <h4 class="mb-4">Join the Biggest API Ecosystem Conference in Hong Kong</h4>
                        <img class="img-fluid mb-4" src="@/assets/img/apidays-2023/images/apidays-event-image.jpg">
                        <p>Discover how APIs can help your business thrive in this dynamic and rapidly evolving landscape at apidays Connect Hong Kong 2023!</p>
                        <router-link :to="{ path: '/' }" class="btn btn-primary">
                          Learn More
                        </router-link>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12 col-md-6 col-lg-12 mb-6">
                    <div id="subscribe" class="card h-100">
                      <div class="card-body">
                        <h4>Be the first to know</h4>
                        <p>Subscribe to our e-newsletter to be the first to receive the latest API news and insights from the leading API Community.</p>
                        <!-- Begin Mailchimp Signup Form -->
                        <div id="mc_embed_signup">
                          <form action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=002b9fe6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                            <div class="row">
                              <div class="col col-md-6">
                                <!-- First name -->
                                <div class="mc-field-group w-100">
                                  <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                                  </label>
                                  <input id="mce-FNAME" type="text" name="FNAME" class="required text" value="" required="">
                                </div>
                              </div>
                              <div class="col col-md-6">
                                <!-- Last name -->
                                <div class="mc-field-group w-100">
                                  <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                                  </label>
                                  <input id="mce-LNAME" type="text" name="LNAME" class="required text" value="" required="">
                                </div>
                              </div>
                            </div>
                            <!-- Email -->
                            <div class="mc-field-group w-100">
                              <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                              </label>
                              <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                            </div>
                            <div class="mb-4">
                              <p class="small text-center">By submitting your information, you agree to receive future communications from apidays.hk and OpenAPIHub by beNovelty.</p>
                            </div>
                            <div hidden=""><input type="hidden" name="tags" value="2985272"></div>
                            <div id="mce-responses" class="clear">
                              <div id="mce-error-response" class="response" style="display: none;" />
                              <div id="mce-success-response" class="response" style="display: none;" />
                            </div>
                            <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                            <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                            <div class="clear"><input id="mc-embedded-subscribe" type="submit" name="subscribe" class="btn btn-primary btn-wide border-0 mx-auto m-0" value="Subscribe"></div>
                          </form>
                        </div>
                        <!--End mc_embed_signup-->

                        <!-- <form ref="subscriptionForm" /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Get Ticket CTA -->
              <!-- <div class="container space-2">
                <div class="card bg-primary text-white overflow-hidden p-4">
                  <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
                    <div class="col-md-6 offset-md-3 mb-3 mb-md-0">
                      <h3 class="text-white mb-1">Join apidays Hong Kong to learn from the best API experts!</h3>
                      <p class="text-white-70 mb-3">Use the 75% discount code "APID23BENOPT" to register now and take advantage of this limited-time offer!</p>
                      <span class="small text-white">* Valid until August 11, 2023</span>
                    </div>
                    <div class="col-md-3 text-md-right">
                      <a class="btn btn-light" href="https://app.moongate.id/events/apidays2023?utm_source=website&utm_medium=website" target="_blank">Get Ticket Now</a>
                    </div>
                  </div>
                  <figure class="w-25 d-none d-md-block content-centered-y ml-n4">
                    <img class="img-fluid" src="@/assets/svg/illustrations/announcement.svg">
                  </figure>
                </div>
              </div> -->
            </div>

          </div>
        </div>
      </div>
      <!-- ========== End Job Description ========== -->

    </main>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import Error404 from '@/views/error404/index'
import '../../../assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Blog',
  components: {
    Error404
  },
  data() {
    return {
      // category: '',
      image_url: '',
      position_detail: {},
      url: '',
      error404occured: false
    }
  },
  computed: {
    ...mapGetters([
      'careers'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    document.getElementById('about').innerHTML = this.position_detail.about
    // document.getElementById('requirement').innerHTML = this.position_detail.requirement
    // document.getElementById('obtainment').innerHTML = this.position_detail.obtainment
  },
  created() {
    this.url = this.$route.params && this.$route.params.url
    const position_list = this.$store.getters.careers.position_list
    // for (let i = 0; i < position_list.length; i++) {
    //   for (let j = 0; j < position_list[i].careers.length; j++) {
    //     if (position_list[i].careers[j].url === this.url) {
    //       this.position_detail = position_list[i].careers[j]
    //       this.image_url = position_list[i].image_url
    //       this.category = position_list[i].category
    //     }
    //   }
    // }

    // Check whether have the info of the parms
    let i = 0
    let found = false
    while ((i < position_list.length) && (found === false)) {
      let j = 0
      while ((j < position_list[i].position.length) && (found === false)) {
        if (position_list[i].position[j].url === this.url) {
          this.position_detail = position_list[i].position[j]
          // this.image_url = position_list[i].image_url
          // this.category = position_list[i].category
          // this.cta_title = position_list[i].cta_title
          // this.cta_description = position_list[i].cta_description
          found = true
        } else {
          j = j + 1
        }
      }
      i = i + 1
    }
    // If not, redirect to error 404 page
    if (found === false) {
      this.error404occured = true
    }
  },
  methods: {
    scrollToList() {
      this.$store.state.careers.scrollToList = true
    }
  },
  metaInfo() {
    return {
      title: 'Apidays Connect Hong Kong 2023',
      titleTemplate: this.position_detail.title + ' | Blog | %s',
      meta: [
        { name: 'description', content: 'Apidays Connect Hong Kong 2023 — The API Landscape of Hong Kong' },
        { property: 'og:site_name', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:title', content: this.position_detail.title + ' | Blog | Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: this.position_detail.about.substring(3, this.position_detail.about.indexOf('</p>')) + '.' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/blog/' + this.url },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>
